*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;

}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out;
    border: 1px solid black;
    border-radius: 10px;
    z-index: 10;
    background-color: white;
    width: 500px;
    max-width: 80%;
}

.modal.active{
    transform: translate(-50%, -50%) scale(1);
}

.modal-H{
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
}

.modal-H .header{
    font-size: 1.5 rem;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.modal-H .close{
    cursor: pointer;
    border:none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
}

.modal-B{
    padding: 10px 15px;
    font-family: 'Courier New', Courier, monospace;
}

#overlay{
    position: fixed;
    opacity: 0;
    transition: 200ms ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

#overlay.active {
    opacity: 1;
    pointer-events: all;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
